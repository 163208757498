a {
  color: #0099ff;
}

a.row-link {
  color: #666666;
  text-decoration: none;
  background-color: #ffffff;
}

header {
  display: flex;
  flex: 1;
  background-color: #ffffff;
  height: 100px;
  margin-bottom: 30px;
}

header h1 {
  color: #00295f;
  margin-left: 50px;
  margin-top: 30px;
  font-size: 32px;
}

.address_link {
  color: #999999;
  text-decoration: none;
}

.link {
  color: #999999;
  cursor: pointer;
}

.section_header {
  font-size: 21px;
  font-weight: bold;
  color: #666666;
  padding-left: 20px;
  padding-bottom: 20px;
}
