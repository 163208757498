.service-daterange {
  display: flex;
  flex-direction: row;

  .service-datepicker {
    display: inline-flex;
    vertical-align: middle;
    line-height: 2.5em;
    margin-right: 1em;

    input {
      padding: 5px;
      margin-left: 0.5em;
      font-size: larger;
    }
  }
}
