.select {
  height: 60px;
  width: 100%;
  color: #999999;
  font-size: 18px;
  background-color: #ffffff;
  border: 1px solid #dddddd;
}

.select--small {
  height: 45px;
  width: 100%;
  color: #999999;
  font-size: 14px;
  background-color: #ffffff;
  border: 1px solid #dddddd;
}

.fleetcor-select .text,
.fleetcor-select .item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block !important;
  cursor: pointer !important;
}
.service_request__options_select.ui.selection.dropdown {
  min-height: 40px;
}
.ui.selection.dropdown.fleet.sign-in-input.width {
  width: 450px !important;
}
