.ui.button {
  background-color: $primary-button-color !important;
  color: $primary-text-color !important;
}

.ui.button:hover {
  background-color: $primary-button-hover-color !important;
  color: $primary-text-color !important;
}

.button-text {
  margin-top: -1px; /* Or else the text is offcenter dont know why */
}

.grey-button-text-color {
  color: $tertiary-button-text-color;
}

.orange-button-text-color {
  color: $primary-button-text-color;
}

.white-button-text-color {
  color: $secondary-button-text-color;
}

.primary-button-text-color {
  color: $primary-button-text-color;
}

.primary-button-color {
  color: $primary-button-color;
}

.grey-button,
.ui.button.grey-button {
  background-color: $tertiary-button-color !important;
  color: $secondary-button-text-color !important;
  border-color: $secondary-button-text-color !important;
}

.grey-button:hover {
  background-color: $tertiary-button-hover-color;
  border-color: $tertiary-button-hover-color;
}

.orange-link {
  color: $primary-button-color;
}

.orange-button {
  background-color: $primary-button-color;
  border-color: $primary-button-color;
}

.orange-button:hover {
  background-color: $primary-button-hover-color;
  border-color: $primary-button-hover-color;
}

.white-button,
.ui.button.white-button {
  background-color: $secondary-button-color !important;
  color: $secondary-button-text-color !important;
  border-color: $secondary-button-text-color !important;
}

.white-button:hover,
.ui.button.white-button:hover {
  background-color: $secondary-button-hover-color !important;
}
