.labeled_checkbox {
  box-sizing: border-box;
  color: #999999;
  display: inline-block;
  background-color: #efefef;
  border-radius: 6px;
  padding: 10px 20px;
  width: 100%;
}

.select-label {
  font-weight: 700;
}

.braintree-setting-modal {
  .__react_component_tooltip {
    left: -6% !important;
    top: 295px !important;
  }
}
