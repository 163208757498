.approvals_form_field__approved_badge {
  background-color: $recharts-primary-color;
  color: $secondary-button-color;
}

.approvals_form_field__price_shop {
  color: $caradvise-electric-blue;
}

.approvals_form_fields_section__header {
  background-color: $recharts-primary-color;
}

.approvals_form_fields_section__header--primary {
  background-color: white;
  color: $recharts-primary-color;
  border-bottom: 2px solid #dddddd;
}

.approvals_form_fields_section__header--secondary {
  background-color: $recharts-primary-color;
  color: white;
  border-bottom: 2px solid #dddddd;
}

.approvals_form_fields_section__header--tertiary {
  background-color: white;
  color: $recharts-primary-color;
  border-bottom: 2px solid #dddddd;
}

.approvals_form_header__heading {
  color: $recharts-primary-color;
}

.approvals_form_header__sub_heading {
  color: $secondary-text-color;
}

.approvals_form_order_comments__icon {
  color: $recharts-primary-color;
}

.approvals_form_payment_method__link {
  color: $recharts-primary-color;
}

.approvals_form_totals__row--total {
  color: $recharts-primary-color;
}
