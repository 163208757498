.shop__address_link {
  color: #666666;
}

.shop__button {
  display: inline-block;
  margin-top: 10px;
}

.shop__favorite_status {
  color: #999999;
  font-size: 14px;
}

.shop__name {
  font-size: 21px;
  font-weight: bold;
}

.shop_filters {
  margin-bottom: 20px;
}

.shop_filters__field {
  height: 35px;
  margin-right: 10px;
  max-width: 200px;
}

.shop_filters__form {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.shop_filters__header {
  font-size: 21px;
  font-weight: bold;
  color: #666666;
  padding-left: 20px;
  padding-bottom: 20px;
}
