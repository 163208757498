@import "variables";

/* ***** Glossary colors: ***** */
// $fleetcor-red: #ee3a43;
$fleetcor-red: #da291c;
$fleetcor-white-hover: #fff;
$fleetcor-red: #dd1d21;
$fleetcor-red-hover: #a51518;
$fleetcor-dark-blue: #dd1d21;
$fleetcor-dark-blue-hover: #dc4c53;
$fleetcor-gray: #fff;
/* **************************** */

$primary-color: $fleetcor-red;
$primary-color-light: rgba(221, 29, 33, 0.1);
$primary-color-hover: $fleetcor-white-hover;
$secondary-color: $fleetcor-red;

$primary-button-color: $fleetcor-dark-blue;
$primary-button-hover-color: $fleetcor-dark-blue-hover;

/* Sign in screen background gradient */
$primary-color-gradient-start: $fleetcor-red;
$primary-color-gradient-end: $fleetcor-red;

/* Recharts */
$recharts-primary-color: $fleetcor-red;
$recharts-secondary-color: $fleetcor-red;
/* $recharts-neutral-color: no override */
$recharts-negative-color: black;

/* Sidebar icons */
$icon-color-active: $fleetcor-red;
$icon-color-inactive: $fleetcor-gray;

$font-family: Futura, Arial, sans-serif !important;
$font-color: $fleetcor-gray;
$header-text-color: $fleetcor-red;
$past-due-color: $fleetcor-red;
$due-soon-color: $fleetcor-dark-blue;

/* This is used in many places- not worth renaming the variable name for now */
$light-blue: $fleetcor-dark-blue;
