// TODO: There is an issue with the "sass loader" used by AG-Grid, and its use of "url()" to import the material icons.
// The solution proposed by the docs does not appear to resolve. This issue needs to be resolved before we can replace
// the /dist/styles import found in the component with the /src/styles imports here.
// $ag-icons-path: '../../../../node_modules/ag-grid-community/src/styles/material-icons/';
// @import '../../../../node_modules/ag-grid-community/src/styles/ag-grid';
// @import '../../../../node_modules/ag-grid-community/src/styles/ag-theme-material';

///////////////////////
// AG GRID OVERRIDES //
///////////////////////

///////////////////////
//      CUSTOM       //
///////////////////////

.ag-grid-link-column {
  color: #000000;
  text-decoration: none;
}

// Changes the font family
$font-family: $font-family;

@import "../../../../node_modules/ag-grid-community/dist/styles/ag-grid.scss";
@import "../../../../node_modules/ag-grid-community/dist/styles/ag-theme-material/sass/legacy/ag-theme-material-v22-compat.scss";
