@import "variables";

$primary-color-gradient-start: #00abdf;
$primary-color-gradient-end: #00b4aa;
$primary-color-hover: #00abe1;

$light-blue: #00abe1;
$primary-color: #0080a8;
$primary-color-light: #f09b95;
$secondary-color: #6bc442;
$button-gradient-start-color: $secondary-color;
$button-gradient-end-color: $secondary-color;
