$primary-color: #002d5e;
$primary-color-gradient-start: #002d5e;
$primary-color-gradient-end: #1b5492;
$primary-color-hover: #082143;
$light-blue: #267dc0;
$secondary-color: white;
$secondary-text-color: #666666;

$primary-text-color: white;
$no-session-content-block-color: white;
$car-advise-orange: #f8991d;
$car-advise-orange-hover: #f8b21c;
$button-gradient-start-color: #fcc93e;
$button-gradient-end-color: #f8991d;
$gray: #666;
$very-light-gray: #f7f7f7;
$light-gray: #efefef;
$red: #ff2d2d;
$primary-button-color: #f8991d;
$primary-button-hover-color: #f68b00;
$secondary-button-color: #f7f7f7;
$secondary-button-hover-color: #fafafa;
$tertiary-button-color: #999999;
$tertiary-button-hover-color: #888888;
$primary-button-text-color: #f7f7f7;
$secondary-button-text-color: #f8991d;
$tertiary-button-text-color: #f7f7f7;
$caradvise-electric-blue: #0390ff;
$neutral-color: rgb(235, 235, 235);
$header-text-color: $primary-color;

$font-family: Muli, Helvetica, Arial, sans-serif !important;
$font-color: $secondary-text-color;

/* Recharts */
$recharts-primary-color: $light-blue;
$recharts-secondary-color: $car-advise-orange;
$recharts-neutral-color: rgb(235, 235, 235);
$recharts-negative-color: $red;

/* Sidebar icons */
$icon-color-active: white;
$icon-color-inactive: white;

$past-due-color: #ff0000;
$due-soon-color: #f5a623;
