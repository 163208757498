.dash-approvals-detail-bottom {
  display: flex;
  flex: 1;
  padding: 10px 20px 10px 20px;
  background-color: #f7f7f7;
}

.dash-approvals-container {
  flex-direction: column;

  margin-bottom: 30px;
}

.dash-upcoming-vehicles-container:hover {
  background-color: #f7f7f7;
}

.dash-upcoming-vehicles-container {
  margin-bottom: 10px !important;
}

.dash-approvals-detail-item {
  padding-left: 5px;
  padding-right: 5px;
}

.dash-approvals-detail-miles {
  text-align: left;
}

.dash-approvals-detail-notes {
  font-size: 14px;
  color: #999999;
}

.dash-approvals-detail-price {
  padding-left: 10px;
}

.dash-approvals-detail-services {
  flex: 2;
}

.dash-approvals-detail-status {
  font-size: 20px;
  text-align: right;
}

.dash-approvals-detail-vehicleinfo {
  font-size: 21px;
}

.dash-approvals-detail-viewdetails {
  align-self: flex-end;
}

.dash-approvals-status {
  color: #ff3232;
}

.dash-hd {
  font-size: 21px;
  font-weight: bold;
  color: #666666;
  padding-top: 20px;
  padding-bottom: 20px;
}

.dash-recalls-container {
  flex-direction: column;

  margin-bottom: 30px;
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);
}

.dashboard__active_maintenance_row {
  width: 100%;
}

.maintenance_approval__dashboard_button {
  width: 120px;
}

@media (max-width: 500px) {
  .ui.segment.segment {
    width: 110vw;
  }

  .ui.menu.site-header {
    min-width: 137vw;
  }

  .ui.vertical.segment.dash-stats-item.item-width {
    width: 90vw;
  }

  .ui.vertical.segment.dash-stats-item {
    width: 105vw;
  }
}
