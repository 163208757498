.alert {
  border: 1px solid;
  border-radius: 6px;
  font-size: 18px;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;

  color: #767676;
  border-color: #bdbdbd;
  background-color: #d5d5d5;
}

//this media query is for Alert on the signup page for screensize less than 325 only.
@media screen and (max-width: 325px) {
  .alert--signup--page {
    width: 88%;
  }
}

.alert--default {
  color: #767676;
  border-color: #bdbdbd;
  background-color: #d5d5d5;
}

.alert--error {
  background-color: #f2dede;
  border-color: #ebcccc;
  color: #a94442;
}

.alert--notice {
  background-color: #d9edf7;
  border-color: #bcdff1;
  color: #31708f;
}

.alert--success {
  background-color: #dff0d8;
  border-color: #d0e9c6;
  color: #3c763d;
}

.alert--warning {
  background-color: #fcf8e3;
  border: 1px solid #faf2cc;
  color: #8a6d3b;
}
