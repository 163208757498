@import "variables";

/* ***** Glossary colors: ***** */
$pepboys-red: #e31837;
$pepboys-red-hover: #e61f54;
$pepboys-dark-blue: #1c345e;
$pepboys-dark-blue-hover: #274661;
/* **************************** */

$primary-color: white;
$primary-color-light: #f09b95;
$primary-color-hover: $very-light-gray;
$primary-color-gradient-start: white;
$primary-color-gradient-end: white;
$no-session-content-block-color: white;

$secondary-color: $pepboys-red;
$secondary-color-hover: $pepboys-red-hover;
$icon-color-active: $pepboys-red;
$icon-color-inactive: $gray;

$primary-button-color: $pepboys-dark-blue;
$primary-button-hover-color: $pepboys-dark-blue-hover;

$secondary-button-color: $pepboys-red;
$secondary-button-hover-color: $pepboys-red-hover;

$past-due-color: $pepboys-red;
$due-soon-color: $pepboys-dark-blue;

$recharts-primary-color: $pepboys-red;
$recharts-secondary-color: $pepboys-red;
/* $recharts-neutral-color: no override */
$recharts-negative-color: black;
