.checkbox-signupbutton {
  // margin-top: 17px;
  margin-bottom: 30px;
  width: 250px;
  display: block;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.checkbox-signupbutton.signup-payment {
  justify-content: flex-start;
}

.checkbox-error {
  margin-top: 17px;
}

.checkbox-premium {
  padding-right: 34%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.t-and-c-checkbox {
  margin-right: 3px;
}

@media screen and (max-width: 325px) {
  .checkbox-signupbutton {
    width: 90%;
  }
}

@media screen and (max-width: 325px) {
  .checkbox-premium {
    margin-top: 17px;
    margin-bottom: 30px;
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }
}
.ag-theme-material .ag-checkbox-input-wrapper.ag-checked::after {
  color: #dd1d21;
}
