.fleet_summary__container {
  display: flex;
  margin: 0 30px 15px 30px;
  justify-content: center;
}

.fleet_summary__label {
  color: #00295f;
  font-size: 12px;
  font-weight: normal;
}

.fleet_summary__tile {
  flex: 1;
  text-align: center;
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.fleet_summary__value {
  color: #00295f;
  font-size: 20px;
  font-weight: bold;
}
