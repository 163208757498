.navbar {
  background-color: $primary-color !important;

  .item.active,
  .item:hover {
    background-color: $primary-color-hover !important;
  }

  .item,
  .header {
    background-color: $primary-color !important;
  }
}

.ui.menu.site-header {
  .item.fleet-label {
    color: $header-text-color !important;
  }
}

.segment.menu-container > .ui.menu > .active.item {
  color: $light-blue;
  border-color: $light-blue;
}

.segment.menu-container > .ui.menu > .active.item:hover {
  color: $light-blue;
  border-color: $light-blue;
}

.segment.menu-container > .ui.menu > .item:hover {
  color: $light-blue !important;
  border-color: $light-blue;
}

.segment.menu-container > .ui.menu > .item {
  color: $gray;
  border-color: #e3e3e3;
}

.segment.menu-container > .ui.menu > .item.skinny-menu-button:hover {
  color: $gray;
  border-color: #e3e3e3;
}
