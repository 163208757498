.link {
  color: $primary-button-color;
}

.primary-color {
  color: $primary-color !important;
}

.positive-color {
  color: $light-blue !important;
}

.negative-color {
  color: $red !important;
}

.neutral-color {
  color: $neutral-color !important;
}
